import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import Bride from '@assets/images/mubashira.jpeg';
import Groom from '@assets/images/azhar.jpeg';

import { styWrapper } from './styles';

function HelloSection({ isInvitation }) {
  const finalSubtitle = '23 December 2023, Saturday';

  return (
    <Fragment>
      <div id="fh5co-couple" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">Assalamualaikum</h2>
              <h3 className="sub-title hs">{finalSubtitle}</h3>
              <p className="info">
                We extend a heartfelt invitation to you and your family to share in the commencement of our new life
                journey, and your presence will bring us immense joy and blessings.
              </p>
            </div>
          </div>
          <div className="couple-wrap">
            <div className="couple-half">
              <div className="groom">
                <img src={Bride} alt="bride" className="img-responsive" loading="lazy" />
              </div>
              <div className="desc-groom">
                <h3 className="main-font">Mubashira M K</h3>
                <p className="parent-name parent-name__top">
                  D/o Mr.Ummer Koya M K <br />& Mrs.Mumthas M
                </p>
              </div>
            </div>
            <p className="heart text-center">
              <i className="icon-heart2"></i>
            </p>
            <div className="and-love">
              <i>&</i>
            </div>
            <div className="couple-half">
              <div className="bride">
                <img src={Groom} alt="groom" className="img-responsive-groom" loading="lazy" />
              </div>
              <div className="desc-bride">
                <h3 className="main-font">Muhammad Azhar Musthafa</h3>
                <p className="parent-name">
                  S/o Mr.Musthafa <br />& Mrs.Shahina Musthafa
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isInvitation
        ? // <div id="fh5co-couple" className="fh5co-section-gray" css={styWrapper}>
          //   <div className="container">
          //     <div className="row">
          //       {/* <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
          //         <h2 className="main-font">Seputar Covid-19</h2>
          //         <p className="info">
          //           Berkaitan dengan kondisi pandemik saat ini, keberlangsungan acara kami mengacu pada pedoman
          //           Kementerian Kesehatan, yaitu memberlakukan pembatasan jarak dan pembatasan jumlah tamu undangan.
          //         </p>
          //         <p className="info">
          //           Maka dari itu, dengan segala kerendahan hati, kami mohon maaf jika belum bisa mengundang saudara/i
          //           dihari bahagia kami. Sekiranya do'a dari saudara/i sudah lebih dari cukup bagi kami. Semoga kita semua
          //           selalu dalam lindungan Allah SWT. Aamiin ya rabbal allamiin..
          //         </p>
          //       </div> */}
          //     </div>
          //   </div>
          // </div>
          null
        : null}
    </Fragment>
  );
}

HelloSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default HelloSection;
