import nikkah from '@assets/images/nikkah.png';
import reception from '@assets/images/reception.png';
import ImgBoarding from '@assets/images/p-boarding-smg.jpg';
import ImgKembaliKeIndonesia from '@assets/images/p-kembali-ke-indonesia.jpg';
import mehandi from '@assets/images/mehandi.png';
import sangeet from '@assets/images/sangeet.png';

export const stories = [
  {
    title: 'Mehandi ',
    date: '21st December 2023 , 6.00pm',
    description:
      'You are warmly invited to join us in the vibrant and intricate celebration of our mehndi, where love, laughter, and henna will adorn our hearts and hands.',
    image: mehandi,
  },
  {
    title: 'Sangeet n Reception ',
    date: '22nd December 2023 , 4.00pm',
    description:
      'Join us for an enchanting evening of music, dance, and joy as we celebrate the Sangeet ceremony, and witness the harmony of our hearts entwining in love.',
    image: sangeet,
  },
  {
    title: 'Nikkah',
    date: '23rd December 2023, 11.00am',
    description:
      'We, cordially invite you to witness and bless our sacred union during our Nikkah ceremony, as we embark on this beautiful journey of love and togetherness. ',
    image: nikkah,
  },
  {
    title: 'Reception',
    date: '25th December 2023, 12.00pm',
    description:
      'Please join us in the joyous celebration of our love at the reception of Mubashira & Azhar, as we mark the beginning of our happily ever after.',
    image: reception,
  },
];
