import irfana from '@assets/images/irfana.jpeg';
import nihal from '@assets/images/nihal.jpeg';
import adhil from '@assets/images/adhilmk.jpeg';
import anjali from '@assets/images/anjali.jpeg';
import neha from '@assets/images/neha.jpeg';
import dad from '@assets/images/dad.jpeg';
import mom from '@assets/images/mom.jpeg';
import sharsoom from '@assets/images/sharsoom.jpeg';
import arya from '@assets/images/arya.jpeg';
import ayisha from '@assets/images/ayisha.jpeg';
import anu from '@assets/images/anu.jpeg';
import shahana from '@assets/images/shahana.jpeg';
import nafidha from '@assets/images/nafidha.jpeg';

export const wishlist = [
  {
    name: 'Irfana',
    infoName: "Brides's sister",
    description: `Finally, you're getting married, and I can see the light at the end of the 'waiting for my turn' tunnel – don't make it too long, sis!"`,
    image: irfana,
  },
  {
    name: 'Nihal',
    infoName: "Bride's brother",
    description: `Congratulations on finding someone who will put up with your quirks for a lifetime – may your marriage be as endlessly entertaining as your dating life! `,
    image: nihal,
  },

  {
    name: 'Adhil',
    infoName: "Bride's brother",
    description: `Cheers to the wedding of my dear sister, who can't navigate a car but is now steering her way into marriage – may your journey together be more smooth-sailing!`,
    image: adhil,
  },
  {
    name: 'Ummer Koya',
    infoName: "Bride's Father",
    description: `Wishing my beloved daughter a lifetime filled with love, happiness, and cherished moments as you embark on this beautiful journey of marriage.`,
    image: dad,
  },

  {
    name: 'Mumthas',
    infoName: "Bride's Mother",
    description: `Insha-Allah, May your marriage be filled with love, happiness, and lifelong togetherness, my dear daughter.`,
    image: mom,
  },

  {
    name: 'Ayisha',
    infoName: "Bride's cousin",
    description: `Happy married life kunjitha!`,
    image: ayisha,
  },
  {
    name: 'Anu',
    infoName: "Bride's cousin",
    description: `As you start this new journey in life, hold on to the love and joy that brought you here, wish you very happy and  blissful married life.`,
    image: anu,
  },
  {
    name: 'Nafidha',
    infoName: "Bride's cousin",
    description: `We look forward to making these days more beautiful and refreshing..may god bless your marriage with love and happiness ☺️`,
    image: nafidha,
  },
  {
    name: 'Shahana',
    infoName: "Bride's cousin",
    description: `Wishing you joy, love and happiness on your wedding day and as you begin your new life together.`,
    image: shahana,
  },
  {
    name: 'Sharsoom',
    infoName: "Brides's bff aka ride or die",
    description: `I have vivid memories of the day we asked you to join the IV, right there on the hostel stairwell. As you begin this new journey, may it be as beautiful as how Azhar is the calm to Mubu's lovely brand of craziness. Here's to a lifetime filled with joy, laughter, and countless beautiful moments ahead!`,
    image: sharsoom,
  },
  {
    name: 'Anjali',
    infoName: "Bride's friend",
    description: `Congrats to the last one standing, our once-speedy friend in the race to the altar! Wishing you a hilarious and happily-ever-after marriage!`,
    image: anjali,
  },
  {
    name: 'Neha',
    infoName: "Bride's Bff",
    description: `Here's to the dynamic duo who made me the 'official third wheel' for so long – may your marriage be as entertaining as our trio adventures!`,
    image: neha,
  },
  {
    name: 'Arya',
    infoName: "Bride's friend",
    description: `Congrats on finding your unicorn in a field of horses to balance your quirks. Late-night dancing and singing random lyrics won't be the same without you, but I'm thrilled for your happily ever after. Here's to endless love, laughter, and unforgettable adventures with your unicorn!`,
    image: arya,
  },
];
