/**
 * Link
 */
export const GOOGLE_CALENDAR_LINK = `https://calendar.google.com/calendar/event?action=TEMPLATE&dates=20201003T040000Z%2F20201003T060000Z&text=Dinda+%26+Indra+Wedding&details=Dinda+%26+Indra+Wedding`;
export const GOOGLE_MAPS_LINK = `https://g.page/HARRISHotelSentralandSemarang?share`;

/**
 * Wedding time
 */
export const EPOCH_START_EVENT = 1613952139;
export const EPOCH_END_EVENT = 1613952139;
export const UTC_WEDDING_TIME = '2023-23-12:11:00:00Z';
